
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      cover:el.querySelector('.proyecto_intro_image'),
      img:el.querySelector('img'),
      title:el.querySelector('.proyecto_intro_title'),
      data:el.querySelector('.col')
    }
    this.device = device
    this.active = 0

    this.create()
    
  }

  async create(){
   


    this.anim = gsap.timeline({paused:true,onComplete:()=>{
      this.DOM.el.classList.add('act')
    }})

    
    .to(this.DOM.el.parentNode,{opacity:1,duration:.6},0)
    if(this.device > 1){

    }
    else{
      this.anim
      
      .fromTo(this.DOM.data,{opacity:0,xPercent:10},{opacity:1,xPercent:0,duration:1.6,ease:Power2.easeInOut},.6)
      .fromTo(this.DOM.title,{x:6.2+'rem',opacity:0},{x:0+'rem',opacity:1,duration:2.4,ease:Power2.easeInOut},0)

      .fromTo(this.DOM.cover,{width:0+'vw'},{width:50+'vw',duration:1.6,ease:Power2.easeInOut},.6)
      .fromTo(this.DOM.img,{width:60+'vw'},{width:50+'vw',duration:2,ease:Power2.easeInOut},.6)
      
      
    }
    // if(this.device > 1){
    //   this.anim
      
    // }
    // else{
    //   this.anim
    
    // }
    


  
  }
  
  async start(){
    await this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
