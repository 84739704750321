

import './index.scss'
export default class{
  constructor (main) {
    this.main = main

  }

  async start(vid){
    
    
    document.documentElement.classList.add('modal-act')
    let videourl = 'https%3A//vimeo.com/'+vid
    const response2 = await fetch('https://vimeo.com/api/oembed.json?url='+videourl+'&responsive=true&autopip=false&color=ffffff&autoplay=true',{
      method: "GET",
    
    })
    const data2 = await response2.json()
    document.body.insertAdjacentHTML('afterbegin','<div class="modal"><button class="modalClose" aria-label="Cerrar Video">[X]</button><div class="modal_content">'+data2.html+'</div></div>')

    this.el = document.querySelector('.modal')
    this.close = this.el.querySelector('.modalClose')
    
    this.close.onclick = async () =>{
      document.dispatchEvent(this.main.events.startscroll)



      this.el.classList.remove('act')

      setTimeout(()=>{
        this.el.remove()
        document.documentElement.classList.remove('modal-act')
      },460)
    }


    setTimeout(()=>{
      this.el.classList.add('act')

    },60)
  }

}