import AutoBind from 'auto-bind'
import Lenis from '@studio-freight/lenis'
//Basic
import Nav from '/components🦾🦾🦾/Nav🌤️'
import Loader from '/components🦾🦾🦾/Loader⏳'

// import Canvas from '/canvas🌊🌊🌊/'


//Mouse
// import Mouse from '/components🦾🦾🦾/Mouse🐭'

import Vimeo from '/components🦾🦾🦾/Vimeo📽️'


// import home from '/views👁️👁️👁️/⚪Home/home.js?url'
// import enterprise from '/views👁️👁️👁️/🔵Enterprise/enterprise.js?url'
// import ai from '/views👁️👁️👁️/🟢Ai/ai.js?url'
// import news from '/views👁️👁️👁️/📚News/news.js?url'

import {
  createViews


} from './👁️.js'

import {
  addPop,
  onPopState,
  onRequest,
  onChange,
  newView


} from './pop.js'

import {
  addEvents,
  // onTouchDown,
  // onTouchMove,
  // onTouchUp,
  // onKeyDown,
  // onWheel,
  // onScroll,
  onResize


} from './events.js'


class App {
  constructor (info) {
    AutoBind(this)
    
    this.content = document.querySelector('#content')
    this.main = info[0]
    this.main.base = info[1].fields.base
    this.main.template = info[1].fields.template

    this.main.screen = {
      w:window.innerWidth,
      h:window.innerHeight
    }

    this.FR = 1e3 / 60

    this.speed = 0
    this.wheeling = 0
    this.isclick = 0
    this.searching = 0
    this.loaded = 0
    this.scry = 0

    this.resizevar = ''
    this.url = window.location.pathname
    this.initApp(info[1])
    
  }
  
  async initApp (temps) {
    //Events
    this.addEvents()
    //Pop
    this.addPop()



    //Lenis
    this.lenis = new Lenis({
      wheelEventsTarget:document.documentElement,
      lerp:.05,
      // duration:.6,
      smoothWheel:!this.main.isTouch,
      smoothTouch:false,
      normalizeWheel:true,
    })

    this.lenis.stop()

    this.createScrollCheck()
    if(this.main.isTouch == 0){
      this.createScrollBar()
    }

    //Loader
    let time = 10
    if(import.meta.env.DEV == true){
      time = 10
    }
    this.template = this.content.dataset.template
    
    this.loader = new Loader(this.main,temps.loader,this.main.device)
      
    await this.loader.create()
    
    
    let firsttemp = undefined
    if(temps.firsttemp){
      firsttemp = temps.firsttemp
    }
    
    
    //PHIDE
    this.pHide = document.createElement('div')
    this.pHide.className = 'pHide'
    document.querySelector('body').appendChild(this.pHide)


    
    //Nav
    this.nav = new Nav(this.main)
    this.nav.create(temps.nav)
   

    //Pages
    this.createViews()
    this.page = this.pages.get(this.template)
    // if(import.meta.env.DEV == false){
    // }
    // else{
    //   loadpage = import(this.pages.get(this.template))
    // }
    console.log(this.main)
    this.vimeos = new Vimeo(this.main)


    // this.video.on('start', ()=>this.controlScroll(0))
    // this.video.on('stop', ()=>this.controlScroll(1))

    //Mouse
    if(!this.main.isTouch && typeof Mouse==='function'){
      this.mouse = new Mouse(this.main)
     
    }
    
    //Lets play
    
    this.update()
    this.loader.start()

	  await Promise.all([
      // loadpage,
      // this.pages.get(this.template),
      this.page.create(this.content,this.main,firsttemp),
      this.timeout(time),
    
    ])

    if(this.canvas){
      this.canvas.createTemp(this.template)
    }
    this.loaded = 1
    this.firstView()
    


  }

  

  async firstView(){
    //Mouse
    if(this.mouse){
      this.mouse.start()
      this.mouse.reset()
    }
    
    await this.loader.hideIntro(this.template)

    this.page.show()
    if(this.canvas){
      this.canvas.show()
    }
    

    //State es para diferenciar entre el firstView y un PopState
    let state = this.page.start(0)

    this.nav.show()

    this.lenis.start()
    this.addControllers()

  }

  
  
  controlScroll(state){
    if(!this.page){
      return false
    }
    if(state==0){
      this.lenis.stop()
      this.page.stopScroll()
    }
    else{
      this.lenis.start()
      this.page.startScroll()

    }
  }

  update(time) {
    window.gsap.updateRoot(time/ 1000)
    if(this.lenis){
      this.lenis.raf(time)
    }

    if (this.page) {
      this.page.update(this.speed)
    }
    
    if (this.mouse) {
      this.mouse.update()
    }
    if (this.canvas) {
      this.canvas.update(this.speed)
    }

    

    window.requestAnimationFrame(this.update)
  }

  timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  
  
  onPopState () {
    this.onChange({
      url: window.location.pathname,
      push: false
    })
  }

  // Controllers son modales, popstates y demás
  addControllers () {
    
    this.resetLinks()

  }


  resetLinks(){

    const langs = document.querySelectorAll('.Lng')


    for(let link of langs){
      link.onclick =  async () =>{
        localStorage.setItem('baron_lang',link.dataset.lang)
        setTimeout(()=>{
          location.reload()
        },12)
      
      }

    }

    const vimeos = document.querySelectorAll('.vimeoBtn')


    for(let link of vimeos){
      link.onclick =  async () =>{
        
        document.dispatchEvent(this.main.events.stopscroll)
       this.vimeos.start(link.dataset.vimeo)
      }

    }


    const links = document.querySelectorAll('a')
    
    const actual = window.location.href
    for(let link of links){

      let isLocal = link.href.indexOf(this.main.base) == 0
      const isAnchor = link.href.indexOf('#') > -1

      if(link.dataset.type && !isAnchor){
        if(import.meta.env.DEV){
          isLocal = true
          if(link.dataset.type){
            link.href = '/'+link.dataset.type+'.html'
          }
        }
        link.removeAttribute('data-type')
      }

     
      


      if (isLocal || isAnchor) {
        link.onclick = async(event) => {
          event.preventDefault()
          
          if (!isAnchor) {
            
            this.onChange({
              url: link.href,
              id: link.dataset.id,
              link:link
              
            })
          }
          else{
            
            if(this.nav.isOpen==1){
              this.nav.isOpen = 0
              this.nav.closeMenu()
              await this.timeout(450)



            }
            if(link.href.split('#').length == 2){
              this.lenis.scrollTo('#'+link.href.split('#')[1],{offset:-100})
            }

          }
        }
      } else if (link.href.indexOf('mailto') === -1 && link.href.indexOf('tel') === -1) {
        link.rel = 'noopener'
        link.target = '_blank'
      }
      //CLEAN CLASS
      if(actual==link.href){
        link.classList.add('actLink')
        
      }
      else{
        link.classList.remove('actLink')
      }
    }
  }


  


  createScrollCheck(){
    if(this.main.isTouch == 0){
      this.scrollFn = ()=>{

        this.speed = this.lenis.velocity
        

        if(this.page){
  
          this.page.scroll.target = this.lenis.targetScroll
          this.page.scroll.current = this.lenis.animatedScroll
          this.page.scroll.last = this.lenis.animatedScroll
  
          this.scrollBar.style.height = gsap.utils.clamp((100*this.lenis.animatedScroll / this.page.scroll.limit))+'%'
  
          this.page.animIosScroll()
        }



        if(Math.abs(this.speed) < 0.01){
          this.pHide.style.pointerEvents = 'none'
        }
        else{
  
          this.pHide.style.pointerEvents = 'all'
        }
        
  
  
        if(this.speed < 0){
          document.documentElement.classList.add('scroll-up')
        }
        else if(this.speed > 0){
          document.documentElement.classList.remove('scroll-up')
  
        }
  
        if(this.lenis.targetScroll == 0){
          document.documentElement.classList.remove('scroll-start')
        }
        else if(this.lenis.targetScroll > 0){
          document.documentElement.classList.add('scroll-start')
  
        }
        
  
        



      }
    }

    else{
      

      this.scrollFn = ()=>{
        this.speed = this.lenis.velocity
        if(Math.abs(this.speed) < 0.01){
          this.pHide.style.pointerEvents = 'none'
        }
        else{
  
          this.pHide.style.pointerEvents = 'all'
        }
        
        if(!this.page){
          return false
        }
  
        if(this.page.scroll.target > this.lenis.targetScroll){
          document.documentElement.classList.add('scroll-up')
        }
        else if(this.page.scroll.target < this.lenis.targetScroll){
          document.documentElement.classList.remove('scroll-up')
  
        }
  
        if(this.lenis.targetScroll == 0){
          document.documentElement.classList.remove('scroll-start')
        }
        else if(this.lenis.targetScroll > 0){
          document.documentElement.classList.add('scroll-start')
  
        }
        
  
        if(this.page){
  
          this.page.scroll.target = this.lenis.targetScroll
          
          this.page.animIosScroll()
        }
      }

    }


    this.lenis.on('scroll',this.scrollFn)
  }

  createScrollBar(){

    const scrollBar = document.createElement('div')
    scrollBar.className = 'scrBar'
    scrollBar.insertAdjacentHTML('afterbegin','<div class="scrBar_prg"></div>')
    
    document.querySelector('body').appendChild(scrollBar)

    this.scrollBar = document.querySelector('.scrBar_prg')

  }


}
//Start
App.prototype.createViews = createViews

//Events
App.prototype.addEvents = addEvents
// App.prototype.onTouchDown = onTouchDown
// App.prototype.onTouchMove = onTouchMove
// App.prototype.onTouchUp = onTouchUp
// App.prototype.onKeyDown = onKeyDown
// App.prototype.onWheel = onWheel
// App.prototype.onScroll = onScroll
App.prototype.onResize = onResize

//Pop
App.prototype.addPop = addPop
App.prototype.onPopState = onPopState
App.prototype.onChange = onChange
App.prototype.onRequest = onRequest
App.prototype.newView = newView

export default App