
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      left:el.querySelector('.left'),
      right:el.querySelector('.right'),
      menu:el.querySelector('.menu'),
      cover:el.querySelector('.home_intro_vid'),
      video:el.querySelector('video'),
    }
    this.device = device
    this.active = 0

    this.create()
    
  }

  async create(){

    this.anim = gsap.timeline({paused:true,onComplete:()=>{
    }})
    
    .fromTo(this.DOM.el,{opacity:0},{opacity:1,duration:.3,ease:Power2.easeInOut},0)
    if(this.device > 1){

      this.anim
      .fromTo(this.DOM.left,{opacity:0},{opacity:1,duration:.6,ease:Power2.easeInOut},0.2)
      .fromTo(this.DOM.right,{opacity:0},{opacity:1,duration:.6,ease:Power2.easeInOut},0.2)
      
      .fromTo(this.DOM.cover,{width:14.4+'rem',height:26.85+'rem'},{width:100+'vw',height:100+'vh',duration:1.6,ease:Power2.easeInOut},1.6)
      .fromTo(this.DOM.video,{width:80+'vw',height:80+'vh'},{width:100+'vw',height:100+'vh',duration:1.4,ease:Power2.easeInOut},1.6)
      .fromTo(this.DOM.left,{y:7.6+'rem'},{y:0+'rem',duration:2.2,ease:Power4.easeInOut},1.2)
      .fromTo(this.DOM.right,{y:-7.6+'rem'},{y:0+'rem',duration:2.2,ease:Power4.easeInOut},1.2)
      
      .fromTo(this.DOM.menu,{opacity:0},{opacity:1,duration:.8,ease:Power2.easeInOut},2)
      .to(this.DOM.cover,{opacity:.8,duration:.8,ease:Power2.easeInOut},2)
  
    }
    else{
      this.anim
      .fromTo(this.DOM.left,{opacity:0},{opacity:1,duration:.6,ease:Power2.easeInOut},0.2)
      .fromTo(this.DOM.right,{opacity:0},{opacity:1,duration:.6,ease:Power2.easeInOut},0.2)
      
      .fromTo(this.DOM.cover,{width:26.4+'rem',height:14.85+'rem'},{width:100+'vw',height:100+'vh',duration:1.6,ease:Power2.easeInOut},1.6)
      .fromTo(this.DOM.video,{width:25+'vw',height:25+'vh'},{width:100+'vw',height:100+'vh',duration:1.4,ease:Power2.easeInOut},1.6)
      .fromTo(this.DOM.left,{x:10+'rem'},{x:0+'rem',duration:2.2,ease:Power4.easeInOut},1.2)
      .fromTo(this.DOM.right,{x:-10+'rem'},{x:0+'rem',duration:2.2,ease:Power4.easeInOut},1.2)
      
      .fromTo(this.DOM.menu,{opacity:0},{opacity:1,duration:.8,ease:Power2.easeInOut},2)
      .to(this.DOM.cover,{opacity:.8,duration:.8,ease:Power2.easeInOut},2)

  }

  
  }
  
  async start(){
    await this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
