import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Intro from './0Intro'



class Home extends Page {
  constructor (main) {
    super(main)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){

      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{
      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.html)
    }
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el
    }

    await this.loadImages()
    await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()
  }
  iOpage(animobj){
    
    return animobj
  }

  
  
  async createComps(){
   


    await super.createComps()
    if(this.DOM.el.querySelector('.proyectos_intro')){
      this.components.intro = new Intro(this.DOM.el.querySelector('.proyectos_intro'),this.main.device)
    
    }
    


    if(this.DOM.el.querySelector('video')){

      for(let [i,a] of this.DOM.el.querySelectorAll('video').entries()){
        
        console.log(a)
        a.setAttribute('webkit-playsinline', 'webkit-playsinline')
        a.setAttribute('playsinline', 'playsinline')

        a.muted = true
        a.autoplay = true
        a.loop = true
        a.parentNode.onmouseenter = () =>{
          console.log(a)
          if(!a.getAttribute('src')){
            a.oncanplay = () =>{
              a.classList.add('Ldd')
              console.log('me cargue')
            }
            a.src = a.dataset.hover
            a.play()
            
            
          
          }
          else{
            a.play()
          }

          



        }
        a.parentNode.onmouseleave = () =>{
          console.log('pause')
          a.pause()
        }
      }

    }
    



  }


  async animIntro(val){
    if(this.components.intro){
     await this.components.intro.start()
    }



    return val
   
  }

  async animOut(){

    document.documentElement.classList.remove('in-quick')
    await gsap.to(this.DOM.el,{opacity:0})

  }

}




export default Home