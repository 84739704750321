
import './index.scss'

class Nav {
  constructor (main) {
   
    this.main = main
    this.isOpen = 0

  }

  async create (temp) {

    document.querySelector('body').insertAdjacentHTML('afterbegin',temp)

   let el = document.querySelector('.nav')
    this.DOM = {
      el:el,
      burger:el.querySelector('.nav_burger'),
      lang:el.querySelector('.nav_lang')
    }

    if(this.DOM.el.querySelector('.nav_bg')){
      this.DOM.bg = this.DOM.el.querySelector('.nav_bg')
    }

    gsap.set(this.DOM.el,{opacity:0})
    


    this.initEvents()
  }
  async openMenu(){
    

    document.documentElement.classList.add('act-menu')
    document.dispatchEvent(this.main.events.openmenu)
  }
  async closeMenu(){
    document.documentElement.classList.remove('act-menu')
    document.dispatchEvent(this.main.events.closemenu)
    
  }

  async show(){

    gsap.fromTo(this.DOM.el,{opacity:0},{opacity:1,ease:Power2.easeInOut,duration:.6})
    this.DOM.el.querySelector('.nav_top').classList.add('act')
  }
  async hide(){
    
    gsap.to(this.DOM.el,{opacity:0,ease:Power2.easeInOut,duration:.6})
  }

  initEvents(){

    if(this.DOM.burger){
      this.DOM.burger.onclick = () =>{
        if(this.isOpen == 1){
          this.closeMenu()
          this.isOpen = 0
        }
        else{
          this.openMenu()
          this.isOpen = 1
        }
      }
    }


    if(this.DOM.bg){
      this.DOM.bg.onclick = () =>{
        this.closeMenu()
        this.isOpen = 0
        
      }
    }

    if(this.DOM.lang){
      this.DOM.lang.onclick = () =>{
        
      }
    }
  }
  

  onResize(){
  }

}



export default Nav